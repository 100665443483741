// eslint-disable-next-line
var mapGetters = Vuex.mapGetters;

Vue.use(VueTables.ClientTable);
const Event = VueTables.Event; // import eventbus

Vue.component('orderStatus', {
    props: ['data', 'index', 'column'],
    template: `<p :class="{'red-text':data.OrderStatus=='PRE','green-text':data.OrderStatus!='PRE'}">{{data.MarkenStatus}}</p>`
});

Vue.component('orderActions', {
    props: ['data', 'index', 'column'],
    computed:{
        viewFilter:function (){
            return this.$route.params.filter;
        },
        canByCopy:function (){
            // if(this.$store.getters.getUserOnlyModifier)
            //     return false;
            return true;
        },
        canByLiberate:function (){
            if(this.viewFilter != 'awaiting')
                return false;
            if (this.$store.getters.getUserOnlyModifier)
                return false;
            // eslint-disable-next-line
            if(store.getters.getUserIsAdmin ) {
                if (this.data.OrderStatus != 'PRE')
                    return false;
            }
            return this.$store.getters.orderCanLiberate;
        }
    },
    mounted:function (){
        // eslint-disable-next-line
        if(!store.getters.getSession)
            this.$router.push({"name":"HomeLink"});
    },
    updated:function (){
        // eslint-disable-next-line
        if(!store.getters.getSession)
            this.$router.push({"name":"HomeLink"});
    },
    methods: {
        orderAction: function (action) {
            this.$store.dispatch('orderAction',{action:action,sernr:this.encode(this.data.SerNr),date:this.data.TransDate,decodeSerNr:this.data.SerNr});
        },
        copyOrder: function () {
            this.$store.dispatch('copyOrder',{orderId:this.data.SerNr});
        },
        viewOrder: function () {
            this.$router.push('/orderdetail/' + this.encode(this.data.SerNr));
        },
        downloadDocument:function (){
            window.open('../ecommerce/api/get_order_document/' + this.encode(this.data.SerNr), "_blank");
        }
    },
    template: `<div :key="'actions-'+data.SerNr" class="custom-actions" >        
                  <button :key="'actions-copy-'+data.SerNr"  title="Copiar" class="col s6 ui basic button" @click.stop="copyOrder()" v-if="canByCopy">
                    <i class="fa fa-copy"></i>
                  </button>
                  <button :key="'actions-edit-'+data.SerNr" :title="tr('View')+'/'+tr('Edit')" class="col s6 ui basic button" @click="viewOrder()" >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button :key="'actions-download-'+data.SerNr"  target="_blank" :title="tr('Download')" class="col s6 ui basic button"  @click="downloadDocument()"  v-if="viewFilter!='awaiting'">
                    <i class="fa fa-download"></i>
                  </button>
                  <button :key="'actions-liberate-'+data.SerNr"  title="Confirmar" class="col s6 ui basic button" @click.stop="orderAction('liberate')" v-if="canByLiberate">
                    <i class="fa fa-check"></i>
                  </button>
                  <button :key="'actions-reject-'+data.SerNr"  title="Rechazar" class="col s6 ui basic button" @click.stop="orderAction('reject')" v-if="canByLiberate">
                    <i class="fa fa-minus"></i>
                  </button>

                </div>`
});
Vue.component('orderViewComponent', {
    data: function () {
        return {
            listMode: true,
            columns:['SerNr','TransDate','Department','CustPurchaseOrder','ContactName','MarkenStatus','SubTotal',"Total","Actions"],
            css: {
                ascendingIcon:  'blue chevron up icon',
                descendingIcon: 'blue chevron down icon',
                table: {
                    loadingClass: 'loading',
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                }
            },
        };
    },
    computed: {
        order_list: function () {
            return this.$store.getters.getOrderList;
        },
        currentOrder: function () {
            return this.$store.getters.getCurrentOrder;
        },
        options: function (h) {
            return {
                preserveState:true,
                filterByColumn: false,
                filterable:["SerNr","TransDate",'Department','MarkenStatus'],
                orderBy: {
                    column:"SerNr",
                    ascending:false
                },
                sortIcon: {
                    up: 'sort-up',
                    down: 'sort-down'
                },
                sortable:['SerNr','TransDate','Department','CustPurchaseOrder','ContactName','MarkenStatus','SubTotal',"Total"],
                query:"",
                headings: {
                    'SerNr':this.tr('Order'),
                    'TransDate':this.tr('Date'),
                    'Department':this.tr('Department'),
                    'CustPurchaseOrder':this.tr('Purchase Order'),
                    'ContactName':this.tr('Contact'),
                    'MarkenStatus':this.tr('Status'),
                    'SubTotal':this.tr('SubTotal'),
                    'Total':this.tr('Total'),
                    'Actions':this.tr('Actions')
                },
                perPage:25,
                pagination: {
                    chunk:15,
                },
                templates:{
                    Actions:'orderActions',
                    MarkenStatus: `orderStatus`
                },
                texts:{
                    count:`Mostrando {from} - {to} de {count} ${this.tr('Sales Orders')}|{count} ${this.tr('Orders')}| Una ${this.tr('Sales Order')}`,
                    filter:this.tr('Search') + ':',
                    limit:'',
                    filterPlaceholder:this.tr('Write a value'),
                    noResults:this.tr('No Sales Orders were found'),
                    page:this.tr('Page') + ":", // for dropdown pagination
                    filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                    loading:this.tr('Loading') + '...', // First request to server
                    defaultOption:'Select {column}', // default option for list filters,
                },
                dateFormat:"DD-MM-YYYY",
                uniqueKey:"SerNr",
                customFilters: [{
                    name: 'alphabet',
                    callback: function (row, query) {
                        //console.log('Test Filter',query,row,row.SerNr==query);
                        if(query != '')
                            return row.SerNr == query;
                        else
                            return true;
                    }
                }]
            };
        },
        listTitle:function () {
            if(this.$route.params.filter == 'all')
                return this.tr("Tracking Orders");
            else
                return this.tr("Orders to Authorize");
        }
    },
    methods: {
        viewOrder: function (event) {
            this.$router.push('/orderdetail/' + event.row.SerNr);
        },
        onLoaded:function (){
            if(this.$route.query.search){
                Event.$emit('vue-tables.filter::alphabet',this.$route.query.search);
            }
        },
        applyFilter:(letter)=>{
            Event.$emit('vue-tables.filter::alphabet', letter);
        },
        eventLog:(event)=>{
        },
        async fetchData() {
            await this.$store.dispatch('loadOrderHistory', this.$route.params.filter);
        }
    },
    watch: {
        '$route.params.filter': "fetchData",
        '$route.params.orderid':"fetchData"
    },
    created: function (){
        this.fetchData();
    },
    updated:function (){
        if(!this.$store.state.session)
            this.$router.push('/home');
        if(this.$route.query.search){
            Event.$emit('vue-tables.filter::alphabet',this.decode(this.$route.query.search));
        }
        else{
            Event.$emit('vue-tables.filter::alphabet','');
        }
    },
    template: `<div class="items-view">
                  <changePasswordModalComponent></changePasswordModalComponent>       
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState=='ready'">
                              <!--<registerComponent></registerComponent>-->
                              <loginComponent></loginComponent>
                              <div class="row">
                                  <div class="left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  </div>
                                  <div class="col m9 s12 l9">
                                    <div id="#orderList">
                                        <h4>{{listTitle}}.</h4>
                                        <v-client-table  :key="$route.params.filter" @rowclick="" :columns="columns" :query="328235" :data="order_list" :options="options" :css="css" @loaded="onLoaded()"></v-client-table>
                                    </div>
                                </div>
                                  <div class="col m2 s12 l2 sticky-component right">
                                      <userSectionComponent></userSectionComponent>
                                      <carComponent></carComponent>
                                  </div>
                              </div>
                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`
});
